<template>
    <form class=" flex justify-center bg-sky-50 min-h-screen" @submit.prevent="login">
        <div
            class="flex  flex-col bg-sky-500 w-full  max-w-[80%] h-fit sm:max-w-md  px-6 py-4 gap-4  mt-6 sm:rounded-lg ">
            <div>
                <label for="email" class="text-lg font-sans font-medium">Email</label>
                <input id="email"
                    class="block mt-1 w-full border-none outline-none px-2 py-1 rounded-sm text-sm placeholder:text-sm"
                    placeholder="Email" type="email" name="email" required autofocus v-model="user.email" />
            </div>
            <div class="mt-4">
                <label for="password" class="text-lg font-sans font-medium">Password</label>
                <input id="password"
                    class="block mt-1 w-full border-none outline-none px-2 py-1 rounded-sm text-sm placeholder:text-sm"
                    type="password" name="password" placeholder="password" required v-model="user.password" />
            </div>
            <div class="flex flex-col items-center justify-end mt-4 gap-4">
                <button v-if="!loading"
                    class="bg-green-500 hover:bg-sky-800 px-3 py-1 rounded-sm text-white text-md font-sans font-bold">Login</button>
                <img v-if="loading" class="w-10 bg-transparent" src="@/assets/loading.gif" alt="loading" />
                <ErrorComponent :errors="store.state.userModule.errors" :error="loginError" />
            </div>
        </div>
    </form>
</template>
<script setup>
import router from '@/router';
import ErrorComponent from './ErrorComponent.vue';

import { ref } from 'vue';
import { useStore } from 'vuex';

const loginError = ref('');
const store = useStore();

const loading = ref(false);

const user = ref({
    email: '',
    password: '',
});

const login = async () => {
    loading.value = true;
    await store.dispatch('userModule/login', user.value);
    loginError.value = store.state.userModule.error
    loading.value = false;

    if (!loginError.value) {
        router.push('/')
    }
};
</script>