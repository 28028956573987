import axios from "axios";

let appUrl = process.env.VUE_APP_URL;
const token = localStorage.getItem("access_token");

export const api = axios.create({
	baseURL: `${appUrl}/api/`,
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
	},
});
