import { api } from "@/utils/api";

const checkinsModule = {
	namespaced: true,

	state: {
		gymCheckins: [],
		calisthenicsCheckins: [],
	},
	actions: {
		async getGymCheckins({ commit }: { commit: any }, date: any) {
			const token = localStorage.getItem("access_token");

			const response = await api.get("/get-gym-checkins", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					date: date,
				},
			});

			console.log("date", date);

			commit("SET_GYM_CHECKINS", response.data.data)
		},
		async getCalisthenicsCheckins({ commit }: { commit: any }, date: any) {
			const token = localStorage.getItem("access_token");

			const response = await api.get("/get-calisthenics-checkins", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					date: date,
				},
			});
			console.log(response.data);

			commit("SET_CALISTHENICS_CHECKINS", response.data.data);
		},
	},
	mutations: {
		SET_GYM_CHECKINS(state: any, checkins: any) {
			state.gymCheckins = checkins;
		},
		SET_CALISTHENICS_CHECKINS(state: any, checkins: any) {
			state.calisthenicsCheckins = checkins;
		},
	},
	getters: {},
};

export default checkinsModule;
