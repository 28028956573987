import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("../views/HomeView.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
	},
	{
		path: "/user/:userId",
		name: "user",
		component: () => import("../views/UserView.vue"),
	},
	{
		path: "/users",
		name: "users",
		component: () => import("../components/Users.vue"),
	},
	{
		path: "/gym-checkins",
		name: "gym-checkins",
		component: () => import("../components/Checkins/GymCheckins.vue"),
	},
	{
		path: "/calisthenics-checkins",
		name: "calisthenics-checkins",
		component: () =>
			import("../views/Checkins/CalisthenicsCheckinsView.vue"),
	},
	{
		path: "/blog",
		name: "blog",
		component: () => import("../views/BlogView.vue"),
	},
	{
		path: "/admin/all",
		name: "admin-all",
		component: () => import("../views/AdminView.vue"),
	},
	{
		path: "/admins",
		name: "admins",
		component: () => import("../views/ManageAdminsView.vue"),
	},
	{
		path: "/new-admin",
		name: "new-admin",
		component: () => import("../views/NewAdminView.vue"),
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: () => import("../views/ResetPasswordView.vue"),
	},
	{
		path: "/hybrid-subscriptions",
		name: "hybrid-subscriptions",
		component: () => import("../views/Hybrid/HybridSubscriptionsView.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth && !isLoggedIn()) {
		next({ name: "login" });
	} else {
		next();
	}
});

function isLoggedIn() {
	const token = localStorage.getItem("access_token");
	return token !== null;
}

export default router;
