import { api } from "@/utils/api";

const paymentModule = {
	namespaced: true,
	state: {
		payments: [],
		hybridSubscriptions: [],
	},
	actions: {
		async getPayments({ commit }: { commit: any }) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.get("/list-mpesa-payments", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});
				commit("setPayments", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async activatePayment({ commit }: { commit: any }, data: any) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.post(
					`/activate-payment/${data.payment_id}`,
					{
						status: data.status,
						amount_paid: data.amount_paid,
						balance: data.balance,
						comments: data.comments,
						payment_type: data.payment_type
					},
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
				console.log(response.data)
				commit("setPayments", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},


		async changePaymentStatus({ commit }: { commit: any }, payment: any) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.post(
					`/update-mpesa-payment-status/${payment.id}`,
					{
						status: payment.status,
					},
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
				commit("setPayments", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async fetchHybridSubscriptions({ commit }: { commit: any }) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.get("/admin/hybrid-subscriptions", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});

				commit("setHybridSubscriptions", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},
	},

	mutations: {
		setPayments(state: any, payments: any) {
			state.payments = payments;
		},

		setHybridSubscriptions(state: any, hybridSubscriptions: any) {
			state.hybridSubscriptions = hybridSubscriptions;
		}
	},
};

export default paymentModule;
