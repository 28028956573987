import { api } from "@/utils/api";

const blogModule = {
	namespaced: true,
	state: {
		success: null,
		error: null,
	},
	mutations: {
		SET_SUCCESS(state: any, success: any) {
			state.success = success;
		},

		SET_ERROR(state: any, error: any) {
			state.error = error;
		},
	},
	actions: {
		async publishBlog({ commit }: { commit: any }, blogData: any) {
			const token = localStorage.getItem("access_token");
			console.log(token);
			try {
				const response = await api.post("publish-blog", blogData, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const success = response.data.success;

				commit("SET_SUCCESS", success);

				setTimeout(() => {
					commit("SET_SUCCESS", null);
				}, 3000);

			} catch (error) {
				commit("SET_ERROR", "error");

				setTimeout(() => {
					commit("SET_ERROR", null);
				}, 3000);
			}
		},
	},
};

export default blogModule;
