import { createStore } from "vuex";
import userModule from "./Auth/userModule";
import paymentModule from "./Auth/paymentModule";
import checkinsModule from "./Others/checkinsModule";
import adminModule from "./Auth/adminModule";
import blogModule from "./Blog/blogModule";

export default createStore({
  modules: {
    userModule: userModule,
    paymentModule: paymentModule,
    checkinsModule: checkinsModule,
    adminModule: adminModule,
    blogModule: blogModule,
  },
});
