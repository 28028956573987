import { api } from '@/utils/api'

const userModule = {
	namespaced: true,

	state: {
		errors: null,
		error: null,
		successStatus: null,
		user: null,
		userDetails: null,
		allUsers: [],
		gymWorkouts: []
	},
	actions: {
		async login({ commit }: { commit: any }, data: any) {
			try {
				const response = await api.post('/admin/login', {
					email: data.email,
					password: data.password
				})
				const token = response.data.token
				const user = response.data.user

				localStorage.setItem('access_token', token)
				localStorage.setItem('user', JSON.stringify(user))
			} catch (error: any) {
				const errors = error.response.data.errors
				const e = error.response.data.message

				commit('setErrors', errors)
				commit('setError', e)

				setTimeout(() => {
					commit('setErrors', null)
					commit('setError', null)
				}, 5000)
			}
		},

		async getUser({ commit }: { commit: any }) {
			try {
				const token = localStorage.getItem('access_token')
				const response = await api.get('/user', {
					headers: {
						Authorization: 'Bearer ' + token
					}
				})
				const user = response.data.user

				commit('setUser', user)
			} catch (error: any) {
				console.log(error)
			}
		},

		async logout({ commit }: { commit: any }) {
			localStorage.removeItem('access_token')
			commit('setUser', null)
		},

		// All Users
		async getAllUsers({ commit }: { commit: any }) {
			const token = localStorage.getItem('access_token')
			try {
				const response = await api.get('/all-users', {
					headers: {
						Authorization: 'Bearer ' + token
					}
				})
				const users = response.data.data

				commit('setAllUsers', users)
			} catch (error: any) {
				console.log(error)
			}
		},

		// user programs
		async getUserDetails({ commit }: { commit: any }, userId: any) {
			const token = localStorage.getItem('access_token')
			try {
				const response = await api.get(`/user-programs`, {
					headers: {
						Authorization: 'Bearer ' + token
					},

					params: {
						user_id: userId
					}
				})

				const programs = response.data.data
				commit('setUserDetails', programs)
			} catch (error: any) {
				console.log(error)
			}
		},

		// updateHybridProgram
		async updateHybridProgram({ commit }: { commit: any }, userData: any) {
			const token = localStorage.getItem('access_token')
			try {
				const response = await api.post(`/update-hybrid-program`, userData, {
					headers: {
						Authorization: 'Bearer ' + token
					}
				})
				const successStatus = response.data.status

				commit('setSuccessStatus', successStatus)

				setTimeout(() => {
					commit('setSuccessStatus', null)
				}, 5000)
			} catch (error: any) {
				console.log(error)
				const e = error.response.data.status

				commit('setSuccessStatus', e)

				setTimeout(() => {
					commit('setSuccessStatus', null)
				}, 5000)
			}
		},

		// Activate hybrid program
		async activateHybridProgram({ commit }: { commit: any }, userData: any) {
			const token = localStorage.getItem('access_token')

			try {
				const response = await api.post(`/activate-hybrid-program`, userData, {
					headers: {
						Authorization: 'Bearer ' + token
					}
				})
				const successStatus = response.data.status

				commit('setSuccessStatus', successStatus)

				setTimeout(() => {
					commit('setSuccessStatus', null)
				}, 5000)
			} catch (error: any) {
				console.log(error)
				const e = error.response.data.status

				commit('setSuccessStatus', e)

				setTimeout(() => {
					commit('setSuccessStatus', null)
				}, 5000)
			}
		},

		async listGymWorkouts({ commit }: { commit: any }) {
			const token = localStorage.getItem('access_token')
			try {
				const response = await api.get(`/list-gym-workouts`, {
					headers: {
						Authorization: 'Bearer ' + token
					}
				})

				const gymWorkouts = response.data.data
				commit('setGymWorkouts', gymWorkouts)
			} catch (error: any) {
				console.log(error)
			}
		}
	},
	getters: {},
	mutations: {
		setSuccessStatus(state: any, successStatus: any) {
			state.successStatus = successStatus
		},
		setErrors(state: any, errors: any) {
			state.errors = errors
		},
		setError(state: any, error: any) {
			state.error = error
		},
		setUser(state: any, user: any) {
			state.user = user
		},
		setUserDetails(state: any, programs: any) {
			state.userDetails = programs
		},
		setAllUsers(state: any, users: any) {
			state.allUsers = users
		},
		setGymWorkouts(state: any, gymWorkouts: any) {
			state.gymWorkouts = gymWorkouts
		}
	}
}
export default userModule
